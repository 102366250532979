export function serializeLayout(grid) {
  const itemIds = grid.getItems().map((item) => item.getElement().dataset.id)
  return JSON.stringify(itemIds)
}

export function saveLayout(keyName, Muuri) {
  window.localStorage.setItem(keyName, serializeLayout(Muuri))
}

export function loadLayout(grid, serializedLayout) {
  const layout = JSON.parse(serializedLayout)
  const currentItems = grid.getItems()
  const currentItemIds = currentItems.map(
    (item) => item.getElement().dataset.id,
  )
  const newItems = []
  let itemId
  let itemIndex

  for (const element of layout) {
    itemId = element
    itemIndex = currentItemIds.indexOf(itemId)
    if (itemIndex > -1) {
      newItems.push(currentItems[itemIndex])
    }
  }

  grid.sort(newItems, { layout: 'instant' })
}

export function checkLocalStorage(keyName, Muuri) {
  const layout = window.localStorage.getItem(keyName)
  if (layout) {
    loadLayout(Muuri, layout)
  } else {
    Muuri.layout(true)
  }
}

export function resetLayout(keyName, Muuri) {
  window.localStorage.removeItem(keyName)
  Muuri.sort((itemA, itemB) => {
    const aId = Number.parseInt(itemA.getElement().dataset.id)
    const bId = Number.parseInt(itemB.getElement().dataset.id)
    return aId - bId
  })
}

export function reRenderMuuri(Muuri) {
  if (Muuri) Muuri.refreshItems().layout()
}

export async function initMuuri(keyName) {
  const dragStartData = new Map()

  const container = document.getElementsByClassName('is-draggable')

  const dragContainer = document.getElementsByClassName('drag-container')
  const phPool = []
  const phElem = document.createElement('div')
  const { default: Muuri } = await import(
    /* webpackChunkName: "muuri" */ '~/node_modules/muuri'
  )
  const startMuuri = new Muuri('.grid-container.is-draggable', {
    dragEnabled: true,
    layoutOnInit: false,
    layout: {
      fillGaps: true,
    },
    dragContainer: dragContainer[0],
    dragCssProps: {
      touchAction: 'auto',
      userSelect: 'none',
      userDrag: 'none',
      tapHighlightColor: 'rgba(0, 0, 0, 0)',
      touchCallout: 'none',
      contentZooming: 'none',
    },
    dragPlaceholder: {
      enabled: true,
      createElement() {
        return phPool.pop() || phElem.cloneNode()
      },
      onRemove(item, element) {
        phPool.push(element)
      },
    },
    dragStartPredicate: (item, e) => {
      if (e.target.className.includes('header')) {
        // On touch, let's allow dragging if the delay between
        // touchstart and touchmove event is 250ms or more.
        // Note that we need to explicitly prevent scrolling
        // after we start the drag delayed.
        if (e.pointerType === 'touch') {
          // On first event (touchstart) we need to store the
          // drag start data and bind listeners for touchmove
          // and contextmenu.
          if (e.isFirst) {
            const contextMenuListener = (e) => e.preventDefault()
            const touchMoveListener = (e) => {
              const data = dragStartData.get(item)
              if (data) {
                if (data.dragAllowed) {
                  e.cancelable && e.preventDefault()
                } else if (data.dragAllowed === undefined) {
                  if (e.cancelable && e.timeStamp - data.startTimeStamp > 250) {
                    data.dragAllowed = true
                    e.preventDefault()
                  } else {
                    data.dragAllowed = false
                  }
                }
              }
            }

            // Store drag start data.
            dragStartData.set(item, {
              dragAllowed: undefined,
              startTimeStamp: e.srcEvent.timeStamp,
              touchMoveListener,
              contextMenuListener,
            })

            // We need to bind the touch move listener to every scrollable ancestor
            // of the dragged item. You probably want to create a method for
            // querying such elements, but in this example we know the specific
            // elements so we explicitly define the listeners for those.
            // Also note that it's important to bind the listeners with
            // capture:true and passive:false options.
            container[0].addEventListener('touchmove', touchMoveListener, {
              passive: false,
              capture: true,
            })

            window.addEventListener('touchmove', touchMoveListener, {
              passive: false,
              capture: true,
            })

            // Prevent context menu popping up.
            item
              .getElement()
              .addEventListener('contextmenu', contextMenuListener)

            // Let's keep the drag start predicate in "pending" state.

            return undefined
          }

          // On final event (touchend/touchcancel) we just need to
          // remove the listeners and delete the item's drag data.
          if (e.isFinal) {
            const data = dragStartData.get(item)
            if (data) {
              container[0].removeEventListener(
                'touchmove',
                data.touchMoveListener,
                { passive: false, capture: true },
              )

              window.removeEventListener('touchmove', data.touchMoveListener, {
                passive: false,
                capture: true,
              })
              item
                .getElement()
                .removeEventListener('contextmenu', data.contextMenuListener)
              dragStartData.delete(item)
            }

            return undefined
          }

          // On move (touchmove) event let's check the drag state from
          // our drag data and return it for the predicate.
          const data = dragStartData.get(item)
          return data ? data.dragAllowed : undefined
        }

        // On mouse let's allow starting drag immediately
        // if mouse's left button is pressed down.
        if (e.isFirst && e.srcEvent.button) {
          return false
        }
        return true
      }
      return false
    },
    dragAutoScroll: {
      targets: (item) => [
        { element: window, priority: 0 },
        { element: item.getGrid().getElement().parentNode, priority: 1 },
      ],
    },
  })
    .on('dragInit', (item) => {
      item.getElement().style.width = `${item.getWidth()}px`
      item.getElement().style.height = `${item.getHeight()}px`
    })
    .on('dragReleaseEnd', (item) => {
      item.getElement().style.width = ''
      item.getElement().style.height = ''
      item.getGrid().refreshItems([item])
    })
    .on('move', () => {
      saveLayout(keyName, startMuuri)
    })

  return startMuuri
}
